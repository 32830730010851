/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $('.full-bio').on('click',function(e){
          e.preventDefault( );
          var full_bio = $(this).parent().find('.full-bio-holder').find('.content');
          var popup = new $.Popup();
          popup.open(full_bio);
        });


        if($(window).width() > 960)
       {
        var left = $('.banner').find('.container').offset().left;
        var full = $('.hero').find('.text').width();
        var percent =  left/full * 100;
        $('.hero').find('.text').find('.copy').css('left',percent/2+50+"%");
        }
        else{
          $('.hero').find('.text').find('.copy').css('left','50%');
        }


        var url = document.location.toString();
        var hash = location.hash;

        //$(window).scrollTop(640);

        if (url.match('#')) {
            setTimeout(function() {
              console.log("match");
                $(window).scrollTop(640);
            }, 1);
            $('.nav-tabs a[href=#'+url.split('#')[1]+']').tab('show') ;

        }

        $('.categories a').on('click',function(e){
          e.preventDefault();
          location.href = $(this).attr('href');
          //$('ul.nav-tabs a[href="' +  e.target.hash + '"]').tab('show');

           var url = document.location.toString();
            if (url.match('#')) {
                $(window).scrollTop(640);
                $('.nav-tabs a[href=#'+url.split('#')[1]+']').tab('show') ;
            }

        });

        // Change hash for page-reload

        if($('.icn_search_input').val() != "")
        {
          $('.icn_search_input').width('80% !important');
        }


        $('.bop-nav-search').on('click',function(){

          /*//$('.icn_search').css('display','none');
          $('.icn_search_input').css('caret-color','#3f4d55');
          $('.icn_search_input').animate({
            'width': '80%'

          } ,300,function(){
            $('.icn_search_input').css('caret-color','#3f4d55');
          });
          */
          //$('.icn_search_input').width('80%');

        });

        $('.bop-nav-search input.icn_search_input').on('blur',function(){


         /* if($(this).val() == ""){

            $('.icn_search_input').animate({

              'width': '30%'

            },200,function(){

              //$('.icn_search').css('display','block');

            });

          }*/

          //$('.icn_search_input').width('30%');


        });

        $('.mobile').on('click',function(e){
          e.preventDefault();

          $('ul.mobile-nav').toggleClass('visible');
        })

        // JavaScript to be fired on all pages
        // JavaScript to be fired on the home page
        var c = $('.testimonials').find('.slide').eq(0).attr('data-color');

        $('.testimonials').find('.swipe').css('background-color',c);

        window.mySwipe = new Swipe(document.getElementById('slider'), {
          startSlide: 1,
          speed: 600,
          auto: 10000,
          continuous: true,
          disableScroll: false,
          stopPropagation: false,
          callback: function(index, elem) {

            var color = $(elem).attr('data-color');

            console.log(color);

            $('.testimonials').find('.swipe').animate({

              'background-color': jQuery.Color( color )

            },600);

          },
          transitionEnd: function(index, elem) {



          }
        });
        $('.filter').on('click',function(e){
          e.preventDefault();
          $('.filter-block').toggleClass('visible');

        });

        $('.filter-link').on('click',function(e){
          var visible = [];
          e.preventDefault();
          $(this).toggleClass('filter-selected');
          $('.filter-link').each(function(){
            var href= $(this).attr('href');
            if($(this).hasClass('filter-selected')){
              visible.push(href);
              console.log(visible);
            }
            else{

            }

            var counter = 0;
            var n = 0;
            $('.project').each(function(){
              counter ++;
              console.log(visible);
              console.log($(this).attr('data-industry'));
              if($.inArray($(this).attr('data-industry'),visible) !== -1)
              {
                $(this).show();
                $(this).addClass('visible-item');

                if(counter == 1 || counter == n*3+1)
                {
                  //console.log($('.project-holder .row:nth-child('+n+')'));
                  //$(this).appendTo($('.project-holder').find('.row:nth-child('+n+')'));
                }

                 if((counter%3) == 0)
                {
                  n++;
                }
              }
              else{
                $(this).hide();
                $(this).removeClass('visible-item');
              }
              if($('.filter-selected').length === 0)
              {
                $('.project').show();
              }
            });

            if($(this).hasClass('news-filter'))
            {
              $('.new-item').each(function(){
              counter ++;
              console.log(visible);
              console.log($(this).attr('data-filter'));
              if($.inArray($(this).attr('data-filter'),visible) !== -1)
              {
                $(this).show();
                $(this).addClass('visible-item');

                if(counter == 1 || counter == n*3+1)
                {
                  //console.log($('.project-holder .row:nth-child('+n+')'));
                  //$(this).appendTo($('.project-holder').find('.row:nth-child('+n+')'));
                }

                 if((counter%3) == 0)
                {
                  n++;
                }
              }
              else{
                $(this).hide();
                $(this).removeClass('visible-item');
              }
              if($('.filter-selected').length === 0)
              {
                $('.new-item').show();
              }
            });
            }
/*

            $('.visible-item').each(function(){

              console.log('dasdasdas');
              counter ++;


            });
*/





          });

        });

        var details;
        var active_project = null;

       /* $('.project').on('click',function(e){
          var project = $(this);

          var project_id = $(project).attr('data-id');
          console.log(project_id);

          //console.log($(active_project));


          if(!project.hasClass('active'))
          {
            details = $(this).find('.project-details').clone();

            $(this).find('.project-details').remove();
            $('.project-details').removeClass('visible').addClass('hidden');
            details.css('position','static');
            details.css('margin-left',0);
            $('.project').removeClass('active');



            if(active_project !== null)
            {
              var details_restore = active_project.parent().next().clone();
              $(details_restore).addClass('hidden');
              active_project.append(details_restore);

            }
            else{

            }
            active_project = $(this);
            project.parent().next().html('');
            project.parent().next().html(details);
            project.parent().next().find('.project-details').removeClass('hidden');
            //

            project.toggleClass('active');
            project.parent().next().find('.project-details').css('height',0);
            project.parent().next().find('.project-details').addClass('visible');
            project.parent().next().find('.project-details').animate({

              height:project.parent().next().find('.project-details').find('.container').height()+60

            },300,function(){

              $('.details-content').css('display','none');

            });


            project.parent().next().width($(window).width());
            var margin = -$('.projects').find('.container').offset().left-15;
            project.parent().next().css('margin-left',margin);

            console.log(document.getElementById(project_id+'-slider'));

            //document.getElementById("'"+project_id+"-slider'")
            var slideShow = document.getElementById(project_id+"-slider");
            var slider = new Swipe(document.getElementById(project_id+"-slider"),{
              startSlide: 0,
              speed: 400,
              continuous: true,
              disableScroll: false,
              stopPropagation: false,
              callback: function(index, elem) {



              },
              transitionEnd: function(index, elem) {
              //console.log($(project).find('.dots').find('li').eq(index));
                $(slideShow).next().find('li').removeClass('active');
                $(slideShow).next().find('li').eq(index).addClass('active');

              }
            });

             $('body').on('click',"."+project_id+"-slidenext",function(e){
             e.preventDefault();
             slider.next();

           });



           $('body').on('click','.details-button',function(e){
             e.preventDefault();

             if($(this).next().css('display') == 'none')
             {
              $(this).next().css('display','block');
             }
             else{
              $(this).next().css('display','none');
             }
           });

          $('body').on('click', "."+project_id+"-slideprev",function(e){
            e.preventDefault();
            //console.log(window['projectSwipe-<?php echo $post->ID;?>'].next());
             slider.prev();

           });
          }
          else{

            project.toggleClass('active');
            $(details_restore).appendTo(project);
            $(details_restore).addClass('hidden');
            console.log(project.parent().next());

          }
        });
 */


        $(window).resize(function(){
          if($(window).width() > 960)
          {
           var left = $('.banner').find('.container').offset().left;
        var full = $('.hero').find('.text').width();
        var percent =  left/full * 100;
        $('.hero').find('.text').find('.copy').css('left',percent/2+50+"%");
        }
        else{
          $('.hero').find('.text').find('.copy').css('left','50%');
        }
        });

        var slideShow = document.getElementById("project-slider");
            var slider = new Swipe(document.getElementById("project-slider"),{
              startSlide: 0,
              speed: 400,
              continuous: true,
              disableScroll: false,
              stopPropagation: false,
              callback: function(index, elem) {



              },
              transitionEnd: function(index, elem) {
              //console.log($(project).find('.dots').find('li').eq(index));
                $(slideShow).next().find('li').removeClass('active');
                $(slideShow).next().find('li').eq(index).addClass('active');

              }
            });




          $('.prev').on('click',function(e){
            e.preventDefault();
            slider.prev();
           });
          $('.next').on('click',function(e){
            e.preventDefault();
            slider.next();
           });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('.dots').find('li').on('click',function(e){

            //$(this).addClass('active');
            console.log('jkfejdjfkhskdhfjs');

        });
      }
    },
    // Home page
    'home': {
      init: function() {

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'services': {
      init: function() {
        // JavaScript to be fired on the about us page
          var url = document.location.toString();
            if (url.match('#')) {
                $('.nav-tabs a[href=#'+url.split('#')[1]+']').tab('show') ;
                $(window).scrollTop(400);
            }




      }
    },

    'single-project':{

      init: function(){

      }

    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
